import PropertiesMixin from './PropertiesMixin'
import ConditionsMixin from './conditions_mixin'

export default {
  // inject: ['getDashboardComponents', 'getModel'],
  mixins: [PropertiesMixin, ConditionsMixin],
  inject: {
    getDashboardComponents: {
      default: () => {
      }
    },
    getCard: {
      default: () => {
      }
    }
  },
  props: {
    CSS: {
      type: String,
      description: 'css_style'
    },
    CSSClasses: {
      type: String,
      description: 'css_classes'
    },
    readonly: {
      description: 'readonly',
      type: Boolean,
      frozen: true
    },
    isReadonly: {
      type: Object,
      editor: 'Conditions',
      options: { label: 'block' },
      default: () => {
        return {
          type: 'never',
          condition_type: 'and',
          conditions: []
        }
      }
    }
  },
  computed: {
    _isReadonly () {
      if (this.alwaysActive) {
        // всегда активно противоречит условиям блокировки или наоборот.
        return false
      }
      return this.checkConditions(this.isReadonly)
    }
  },
  mounted () {
    if (this.readonly) {
      this.$emit('change-property', {
        name: 'isReadonly',
        value:
          {
            condition_type: 'and',
            conditions: [],
            type: 'always'
          }
      })
      this.$set(this.isReadonly, 'type', 'always')
      this.$emit('change-property', { name: 'readonly', value: false })
    }
  }
}
