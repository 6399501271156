import ConditionsMixin from './conditions_mixin'

export default {
  mixins: [ConditionsMixin],
  props: {
    required: {
      type: Boolean,
      description: 'required',
      frozen: true
    },
    isRequired: {
      type: Object,
      editor: 'Conditions',
      options: { label: 'required' },
      default: () => {
        return {
          type: 'never',
          condition_type: 'and',
          conditions: []
        }
      }
    }
  },
  computed: {
    _isRequired () {
      if (this.isHidden || this.isHiddenFromBlock) {
        return false
      }
      return this.checkConditions(this.isRequired)
    }
  }
}
